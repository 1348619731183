export default function env() {
  if (process.env.REACT_APP_PROD) {
    return {
      PRODUCTION: true,
      uri: 'https://api.relaxstreamtv.com/backend/rest/v1',
    };
  }

  if (process.env.REACT_APP_STAGE) {
    return {
      PRODUCTION: false,
      uri: 'https://stage-api.relaxstreamtv.com/backend/rest/v1',
    };
  }

  return {
    PRODUCTION: false,
    uri: 'https://dev-api.relaxstreamtv.com/backend/rest/v1',
  };
}
